import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    gradient: {
      info: 'linear-gradient(45deg,rgb(50, 148, 165),rgb(112, 199, 171))',
      dark: 'linear-gradient(-45deg,rgb(12, 21, 33),rgb(17, 28, 43))',
      success: 'linear-gradient(45deg,#46be8a,#92d275)',
      warning: 'linear-gradient(45deg,#f1aa61,#f5754f)',
      error: 'linear-gradient(45deg,#b55959,#f54f4f)',
    },
    background: {
      paper: 'rgba(17, 28, 43, 1)',
      default: 'rgba(25, 41, 58, 1)',
      dark: 'rgba(12, 21, 33, 1)',
      // nav: 'rgba(5, 36, 53, 1)',
      nav: 'rgba(14, 24, 38, 1)',
      navLight: 'rgba(40, 69, 86, 1)',
      light: 'rgba(35, 48, 68, 1)',
      extraLight: 'rgba(63, 79, 103, 1)',
    },
    primary: {
      light: '#8490c7',
      main: '#8490c7',
      dark: 'rgba(35, 152, 101, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
      // light: 'rgba(97, 222, 164, 1)',
      // main: 'rgba(98, 189, 147, 1)',
      // dark: 'rgba(35, 152, 101, 1)',
      // contrastText: 'rgba(255, 255, 255, 1)',
    },
    secondary: {
      light: '#ff4081',
      main: 'rgba(169, 190, 212, 1)',
      dark: '#c51162',
      contrastText: '#fff',
    },
    error: {
      light: 'rgba(255,160,0,1)',
      main: 'rgba(223, 57, 57, 1)',
      dark: 'rgba(211, 47, 47, 1)',
      contrastText: '#fff',
    },
    text: {
      white: 'rgba(250, 250, 250, 1)',
      primary: 'rgba(236, 255, 246, 1)',
      secondary: 'rgba(169, 190, 212, 1)',
      secondaryTransparent: 'rgba(169, 190, 212, .3)',
      disabled: 'rgba(242, 149, 34, 1)',
      hint: 'rgba(242, 149, 34, 1)',
    },
  },
  typography: {
    useNextVariants: true,
  },
//   breakpoints: {
//     keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
//     values: {
//       xs: 0,
//       sm: 300,
//       md: 400,
//       lg: 600,
//       xl: 1200,
//       xxl: 1500,
//     },
//   },
});

export default theme;
