import React from 'react';
import { Typography } from '@material-ui/core';
import GoogleSheetJobManagerItem from './GoogleSheetJobManager/GoogleSheetJobManagerItem';

function GoogleSheetJobManager(props) {
  const { jobs, onJobItemChange, onJobItemRemove, open } = props;

  return (
    <div className="pl-2 pr-2">
      {
          jobs.length > 0 ? (
            <React.Fragment>
              <div className={!open ? 'hide' : ''}>
                <Typography variant="body2" className="colorSecondary">
                  You have created {jobs.length} jobs. Jobs are removed automatically after 1 week, but the related videos are not deleted on Danim when a job is removed.
                </Typography>
                {jobs.map(job => (
                  <GoogleSheetJobManagerItem open={open} job={job} key={job.id} onJobItemRemove={onJobItemRemove} onJobItemChange={onJobItemChange} />
                ))}
              </div>
            </React.Fragment>
          ) : open ? (
            <Typography variant="body2" className="colorSecondary">
              You haven't created any job for now...
            </Typography>
          ) : null
      }
    </div>
  );
}

export default GoogleSheetJobManager;
