import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Select from 'react-select';
import { find } from 'lodash-es';
import SelectWithThumbnail from './UX/SelectWithThumbnail';

function TemplateSelector(props) {
  const { isLoading, onTemplateChange, onWorkspaceChange, workspace, template, data } = props;

  const handleWorkspaceChange = (value) => {
    onWorkspaceChange(value);
  };

  const handleTemplateChange = (value) => {
    onTemplateChange(value);
  };

  const availableWorkspaces = useMemo(() => data.map(_workspace => ({
    value: {
      workspaceId: _workspace.workspaceId,
      isPublic: _workspace.isPublic,
    },
    label: _workspace.workspaceName,
  })), [data]);

  const availableTemplates = useMemo(() => {
    const _workspace = workspace ? find(data, workspace.value) : null;
    return _workspace ? _workspace.templates.map(_template => ({
      label: _template.name,
      thumbnail: _template.thumbnail,
      specs: _template.specs,
      value: _template.id,
    })) : [];
  }, [workspace, data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Typography variant="overline">Workspace</Typography>
        <Select
          className="react-select darker"
          classNamePrefix="react-select"
          isLoading={isLoading}
          isSearchable
          placeholder="Select a workspace"
          options={availableWorkspaces}
          value={workspace}
          onChange={handleWorkspaceChange}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="overline">Template</Typography>
        <SelectWithThumbnail
          className="react-select darker"
          classNamePrefix="react-select"
          isLoading={isLoading}
          isSearchable
          placeholder="Select a template"
          options={availableTemplates}
          value={template}
          onChange={handleTemplateChange}
        />
      </Grid>
    </Grid>
  );
}

export default TemplateSelector;
