import React, { useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Icon, Accordion, AccordionSummary, AccordionActions, Button, AccordionDetails, Typography, Chip, LinearProgress } from '@material-ui/core';
import { get } from 'lodash-es';
import objectToArray from '../../utilities/objectToArray';
import apiClient from '../../utilities/apiClient';

const useStyles = makeStyles(() => ({
  progress: {
    height: '5px',
  },
}));

function GoogleSheetJobManagerItem(props) {
  const { job, onJobItemChange, onJobItemRemove, open } = props;
  const { id, data } = job;
  const classes = useStyles();

  useEffect(() => {
    const unsubscribe = firestore.collection('jobs').doc(job.id)
      .onSnapshot((doc) => {
        onJobItemChange(id, doc.data());
      });
    return () => {
      unsubscribe();
    };
  }, []);

  const videos = useMemo(() => {
    if (!data) return [];
    const _videos = objectToArray(data.videos);
    _videos.sort((a, b) => (a.index > b.index ? 1 : -1));
    return _videos;
  }, [data]);

  const counter = useMemo(() => {
    const _counter = {
      error: 0,
      pending: 0,
      creating: 0,
      done: 0,
    };
    videos.forEach(video => {
      let { state } = video;
      if (state === 'sending') state = 'pending';
      if (!_counter[state]) _counter[state] = 0;
      _counter[state]++;
    });
    _counter.processed = _counter.error + _counter.done;
    return _counter;
  }, [videos]);

  const handleRemoveClick = () => {
    apiClient.fetch('job/remove', { jobId: id }).then(res => {
      const { success } = res;
      if (!success) notify.error(message);
      else {
        notify.success(`Job ${data.title} has been removed`);
        onJobItemRemove(id);
      }
    }).catch(e => {
      notify.error(e.message || e.toString());
    });
  };

  if (!data) return null;
  return open && (
    <div className="mt-2 mb-2">
      <Accordion className="m-0">
        <AccordionSummary
          expandIcon={<Icon className="colorSecondary">expand_more</Icon>}
        >
          <Typography variant="body2">
            {data.title} 
            {counter.processed !== videos.length && <Chip size="small" color="primary" className="ml-2 w-bold" label={`${counter.processed} / ${videos.length}`} />}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={clsx(classes.defaultBackground, 'block')}>
          <Typography variant="caption" className="colorSecondary mr-2">Total: {videos.length}</Typography>
          { counter.pending ? <Typography variant="caption" className="colorSecondary mr-2">Pending: {counter.pending}</Typography> : null}
          { counter.creating ? <Typography variant="caption" className="colorSecondary mr-2">Waiting on Danim: {counter.creating}</Typography> : null}
          { counter.done ? <Typography variant="caption" className="colorLight mr-2">Done: {counter.done}</Typography> : null}
          { counter.error ? <Typography variant="caption" className="colorWarning mr-2">Errors: {counter.error}</Typography> : null}
          <ul className="mt-2">
            {videos.map(video => (
              <li key={video.id} className="pb-1">
                <Typography variant="caption" className={clsx(video.state === 'done' ? 'colorLight' : video.state === 'error' ? 'colorWarning' : 'colorSecondary')}>
                  <span className="w-bold">{video.title}</span>: 
                  { get(video, 'data.shortId') ? (
                    <a className="colorSecondary ml-1" target="_blank" rel="noreferrer" href={`https://app.danim.com/${video.data.shortId}`}>https://app.danim.com/{video.data.shortId}</a> 
                  ) : video.state !== 'error' ? ' pending' : null }
                </Typography>
                {video.error && <Typography variant="caption" className="ml-1 colorSecondary">{video.error}</Typography>}
              </li>
            ))}
          </ul>
          <Typography variant="caption">G-Sheet: <a href={`https://docs.google.com/spreadsheets/d/${data.spreadsheetId}/edit#gid=0`} target="_blank" rel="noreferrer">{data.spreadsheetId}</a></Typography>
        </AccordionDetails>
        {
          counter.processed === videos.length ? (
            <AccordionActions>
              <Button size="small" color="primary" onClick={handleRemoveClick}>Remove</Button>
            </AccordionActions>
          ) : null
        }
      </Accordion>
      {
          counter.processed !== videos.length && (
            <LinearProgress variant="determinate" className={classes.progress} value={(counter.processed / videos.length) * 100} />
          )
      }
    </div>
  );
}

export default GoogleSheetJobManagerItem;
