/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Avatar } from '@material-ui/core';
import Select, { components } from 'react-select';

const Option = props => {
  const { data } = props;
  const { label, thumbnail } = data;
  return (
    <components.Option {...props}>
      <Avatar alt={label} src={thumbnail} variant="rounded" className="select-thumbnail-image" />
      <span className="select-thumbnail-label">{label}</span>
    </components.Option>
  );
};

function SelectWithThumbnail(props) {
  return (
    <Select
      {...props}
      components={{ Option }}
    />
  );
}

export default SelectWithThumbnail;
