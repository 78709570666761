import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import { Grid, Typography, Card, CardMedia, InputAdornment, IconButton, CardContent, Button, Divider, TextField, Icon, CircularProgress, Link, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { clone, get, find } from 'lodash-es';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Dialog } from 'dnm-react-mui-root';
import apiClient from '../utilities/apiClient';
import useObjectState from '../utilities/hooks/useObjectState';
import TemplateSelector from './TemplateSelector';
import useToggle from '../utilities/hooks/useToggle';

const useStyles = makeStyles((theme) => ({
  templateCardRoot: {
    display: 'flex',
    width: '100%',
    margin: 'auto',
  },
  templateCardDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  templateCardContent: {
    flex: '1 0 auto',
  },
  templateCardCover: {
    width: 151,
  },
  defaultBackground: {
    backgroundColor: theme.palette.background.default,
  },
}));

function checkSpecsIntegrity(specs) {
  const { compositions } = specs;
  for (let i = 0; i < compositions.length; i++) {
    const comp = compositions[i];
    const { inputs, willBeIgnored } = comp;
    for (let n = 0; n < inputs.length; n++) {
      const input = inputs[n];
      if (input.required && !input.gsheetId) {
        if (!willBeIgnored) return false;
      }
    }
  }
  return true;
}

function formatSpecs(specs) {
  if (specs) {
    specs.compositions.forEach(comp => {
      const { required, inputs } = comp;
      let willBeIgnored = false;
      if (!required) {
        willBeIgnored = true;
        inputs.forEach(input => {
          if (input.gsheetId) willBeIgnored = false;
        });
      }
      comp.willBeIgnored = willBeIgnored;
    });
  }
  return specs;
}

function getInputsMapFromSpecs(specs, usePrivateIds = false) {
  const map = {};
  if (specs) {
    specs.compositions.forEach(comp => {
      comp.inputs.forEach(input => {
        const { gsheetId, _id, id, category } = input;
        map[usePrivateIds ? _id : id] = {
          gsheetId,
          cat: category,
        };
      });
    });
  }
  return map;
}

function inputStringMapToIndexMap(map, firstRow) {
  const newMap = {};
  for (const key in map) {
    newMap[key] = firstRow.indexOf(map[key].gsheetId);
  }
  return newMap;
}

function GoogleSheetImporter(props) {
  const classes = useStyles();
  const [gsheetUrlState, setGsheetUrlState, resetGsheetUrlState] = useObjectState({
    url: '',
    isLoading: false,
    badSpreadsheetId: false,
    spreadsheetId: null,
    data: null,
  }); 
  const [manualTemplateSelectionState, editManualTemplateSelectionState, resetManualTemplateSelectionState] = useObjectState({
    selectedWorkspace: null,
    selectedTemplate: null,
  });
  const [newJobDialogState, editNewJobDialogState, resetNewJobDialogState] = useObjectState({
    open: false,
    title: '',
  });
  const [remapAccordionIsOpen, setToggleAccordionIsOpen, toggleRemapAccordionIsOpen] = useToggle(false);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [activeVideoIndexInExplorer, setActiveVideoIndexInExplorer] = useState(0);
  const { open, availableTemplatesByWorkspaces, onJobCreation } = props;
  const values = get(gsheetUrlState, 'data.values');

  const gsheetIds = useMemo(() => {
    if (values && values[0]) return values[0];
    return [];
  }, [values]);

  const videosVariants = useMemo(() => {
    const videos = [];
    const specs = get(activeTemplate, 'specs');
    if (values && values.length > 1 && specs) {
      const titleGsheetId = get(activeTemplate, 'metas.titleGsheetId');
      const titleGsheetIndex = titleGsheetId ? values[0].indexOf(titleGsheetId) : -1;
      const indexedInputMap = inputStringMapToIndexMap(getInputsMapFromSpecs(specs), values[0]);
      for (let i = 1; i < values.length; i++) {
        const line = values[i];
        let title = `Video ${i}`;
        if (titleGsheetIndex !== -1 && line[titleGsheetIndex]) title = line[titleGsheetIndex];
        const video = { title, inputs: [] };
        for (const key in indexedInputMap) {
          video.inputs.push({
            id: key,
            value: indexedInputMap[key] !== -1 ? (line[indexedInputMap[key]] || null) : null,
          });
        }
        videos.push(video);
      }
    }
    return videos;
  }, [values, activeTemplate]);

  const videoExplorerSelectOptions = useMemo(() => videosVariants.map((video, videoIndex) => ({ value: videoIndex, label: video.title })), [videosVariants]);
  const activeVideo = videosVariants[activeVideoIndexInExplorer];
  
  const remapIsNeeded = activeTemplate ? !checkSpecsIntegrity(activeTemplate.specs) : true;

  useEffect(() => {
    if (window.devState.gsheetUrl) {
      setTimeout(() => handleGsheetUrlChange({ target: { value: window.devState.gsheetUrl } }), 1000);
    }
  }, []);

  useEffect(() => {
    resetManualTemplateSelectionState();
  }, [availableTemplatesByWorkspaces.data]);

  useEffect(() => {
    const template = get(gsheetUrlState, 'data.template') || (manualTemplateSelectionState.selectedTemplate ? {
      ...manualTemplateSelectionState.selectedTemplate,
      id: manualTemplateSelectionState.selectedTemplate.value,
      name: manualTemplateSelectionState.selectedTemplate.label,
    } : null);
    const inputsMap = get(gsheetUrlState, 'data.inputsMap') || {};
    if (template) {
      const oldMap = getInputsMapFromSpecs(get(activeTemplate, 'specs'));
      template.specs = clone(template.specs);
      template.specs.compositions.forEach(comp => {
        comp.inputs.forEach(input => {
          const { id, _id } = input;
          if (oldMap[id] && gsheetIds.indexOf(oldMap[id].gsheetId) !== -1) input.gsheetId = oldMap[id].gsheetId;
          else if (gsheetIds.indexOf(id) !== -1) input.gsheetId = id;
          else if (inputsMap[_id] && gsheetIds.indexOf(inputsMap[_id]) !== -1) input.gsheetId = inputsMap[_id];
          else input.gsheetId = null;
        });
      });
      template.specs = formatSpecs(template.specs);
      const oldMetas = get(activeTemplate, 'metas') || {};
      template.metas = {
        titleGsheetId: (oldMetas && gsheetIds.indexOf(oldMetas.titleGsheetId) !== -1) ? oldMetas.titleGsheetId : gsheetIds.indexOf('video-title') !== -1 ? 'video-title' : null,
      };
      setToggleAccordionIsOpen(!checkSpecsIntegrity(template.specs));
    }
    setActiveTemplate(template);
  }, [gsheetIds, get(gsheetUrlState, 'data.template'), manualTemplateSelectionState.selectedTemplate, get(gsheetUrlState, 'data.inputsMap')]);

  const updateTemplateFromUrl = (url) => {
    if (url) {
      const spreadsheetId = get(getSpreadsheetIdFromUrl(url), 1);
      setGsheetUrlState({ isLoading: !!spreadsheetId, badSpreadsheetId: !spreadsheetId, spreadsheetId });
      if (spreadsheetId) {
        apiClient.fetch('gsheet/read', { spreadsheetId, filterWithoutAutomatorId: true }).then(res => {
          const { success, message, template, values: _values, inputsMap } = res;
          if (!success && message !== 'bad_token') notify.error(message);
          else if (success) setGsheetUrlState({ data: { template, values: _values, inputsMap } });
        }).catch(e => {
          notify.error(e.message || e.toString());
        }).finally(() => setGsheetUrlState({ isLoading: false }));
      }
    } else resetGsheetUrlState();
  };

  const getSpreadsheetIdFromUrl = (url) => url.match(/.*[^-\w]([-\w]{25,})[^-\w]?.*/);

  const handleSelectedWorkspaceChange = (value) => {
    editManualTemplateSelectionState({ selectedWorkspace: value, selectedTemplate: null });
  };

  const handleSelectedTemplateChange = (value) => {
    editManualTemplateSelectionState({ selectedTemplate: value });
  };

  const handleGsheetUrlChange = event => {
    const { value } = event.target;
    setGsheetUrlState({ url: value });
    updateTemplateFromUrl(value);
  };

  const handleRefreshClick = () => {
    updateTemplateFromUrl(gsheetUrlState.url);
  };

  const handleManualLinkClick = () => {
    setGsheetUrlState({
      data: {
        ...gsheetUrlState.data,
        template: null,
      },
    });
  };

  const handleGsheetIdOnInputChange = (compIndex, inputIndex, value) => {
    setActiveTemplate(state => {
      const newState = clone(state);
      newState.specs.compositions[compIndex].inputs[inputIndex].gsheetId = value ? value.value : null;
      newState.specs = formatSpecs(newState.specs);
      return newState;
    });
  };

  const handleGsheetIdOnMetaChange = (metaId, value) => {
    setActiveTemplate(state => {
      const newState = clone(state);
      newState.metas[metaId] = value ? value.value : null;
      return newState;
    });
  };

  const handleVideoExplorerSelectChange = (value) => {
    setActiveVideoIndexInExplorer(value ? value.value : null);
  }; 

  const handleCreateNewJobClick = () => {
    if (remapIsNeeded) {
      notify.warning('Some inputs are missing in your G-sheet');
    } else if (values.length < 2) {
      notify.warning('There isn\'t any video to create');
    } else {
      editNewJobDialogState({ open: true });
    }
  };

  const handleCreateNewJobTitleChange = (event) => {
    editNewJobDialogState({ title: event.target.value });
  };

  const handleCreateNewJobDialogConfirm = () => {
    const job = {
      title: newJobDialogState.title,
      spreadsheetId: gsheetUrlState.spreadsheetId,
      map: {
        metas: activeTemplate.metas,
        inputs: getInputsMapFromSpecs(activeTemplate.specs, true),
      },
      templateId: activeTemplate.id,
      count: videosVariants.length,
    };
    loading.show(`Job ${job.title} is being created...`);
    apiClient.fetch('job/create', { job }).then(res => {
      const { success, message, jobId, skippedCount } = res;
      if (!success && message !== 'bad_token') notify.error(message);
      else if (success) {
        notify.success('Job has started!');
        if (skippedCount) notify.warning({ message: `${skippedCount} videos have been ignored because they're managed by another job`, timeout: 0 });
        onJobCreation(jobId);
      }
    }).catch(e => {
      notify.error(e.message || e.toString());
    }).finally(() => {
      resetNewJobDialogState();
      loading.hide();
    });
  };

  return open ? (
    <div>
      <TextField
        variant="filled"
        className="w-full"
        value={gsheetUrlState.url}
        onChange={handleGsheetUrlChange}
        label="Google Sheet URL"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleRefreshClick}
              >
                <Icon className="colorSecondary">refresh</Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {
          !gsheetUrlState.url && !gsheetUrlState.isLoading ? (
            <Typography variant="body2" className="mt-1 colorWarning">Please define a G-sheet url</Typography>
          ) : gsheetUrlState.isLoading ? (
            <CircularProgress className="centerProgress mt-2" />
          ) : gsheetUrlState.badSpreadsheetId ? (
            <Typography variant="body2" className="mt-1 colorWarning">Invalid url</Typography>
          ) : null
      }
      {
        gsheetUrlState.data && (
          <div className="mt-1 mb-1">
            {
              !gsheetUrlState.data.template ? (
                <TemplateSelector 
                  data={availableTemplatesByWorkspaces.data}
                  isLoading={availableTemplatesByWorkspaces.isLoading} 
                  workspace={manualTemplateSelectionState.selectedWorkspace}
                  onWorkspaceChange={handleSelectedWorkspaceChange} 
                  template={manualTemplateSelectionState.selectedTemplate}
                  onTemplateChange={handleSelectedTemplateChange}
                />
              ) : null
            }
          </div>
        )
      }
      {
        activeTemplate && (
          <Card className={clsx(classes.templateCardRoot, 'mt-2')}>
            <CardMedia
              image={activeTemplate.thumbnail}
              title={activeTemplate.name}
              className={classes.templateCardCover}
            />
            <div className={classes.templateCardDetails}>
              <CardContent className={classes.templateCardContent}>
                <div className="mb-2">
                  <Typography component="h5" variant="h5" className="float-left">
                    {activeTemplate.name}
                  </Typography>
                  <Link className="cursor float-right" onClick={handleManualLinkClick}>Link it to another template</Link>
                </div>
                <div className="clearfix" />
                <div>
                  <Accordion expanded={remapAccordionIsOpen} onChange={toggleRemapAccordionIsOpen} className={clsx('mt-2 mb-2', classes.defaultBackground, 'accordionInputs')}>
                    <AccordionSummary
                      expandIcon={<Icon className="colorSecondary">expand_more</Icon>}
                    >
                      <Typography variant="body2"><span className="w-bold">Fields resolver: </span> <span className={remapIsNeeded ? 'colorWarning' : 'colorLight'}>{ remapIsNeeded ? 'Some inputs are missing in your G-Sheet' : 'All required inputs have been found' }</span></Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.defaultBackground}>
                      <Typography variant="subtitle1" className="colorSecondary">Video title</Typography>
                      <Select
                        className={clsx('react-select darker', !activeTemplate.metas.titleGsheetId && 'react-select-error')}
                        classNamePrefix="react-select"
                        isSearchable
                        placeholder="Select a G-Sheet column"
                        options={gsheetIds.map(id => ({ value: id, label: id }))}
                        value={activeTemplate.metas.titleGsheetId && { value: activeTemplate.metas.titleGsheetId, label: activeTemplate.metas.titleGsheetId }}
                        onChange={(value) => handleGsheetIdOnMetaChange('titleGsheetId', value)}
                      />
                      <div className="mt-2">
                        {
                          activeTemplate.specs.compositions.map((comp, compIndex) => {
                            const { inputs, willBeIgnored, description } = comp;
                            return (
                              <div key={compIndex} className="w-full mb-2">
                                <Typography variant="subtitle1" display="inline" className="w-bold colorSecondary">
                                  { description }{ willBeIgnored ? ' (will be ignored)' : ''}
                                </Typography>
                                <Grid container spacing={1} className="mt-05">
                                  {
                                  inputs.map((input, inputIndex) => (
                                    <Grid item key={inputIndex} xs={12} sm={6} lg={4}>
                                      <Typography variant="caption">{input.description}</Typography>
                                      <Select
                                        className={clsx('react-select darker', !willBeIgnored && input.required && !input.gsheetId && 'react-select-error')}
                                        classNamePrefix="react-select"
                                        isSearchable
                                        isClearable
                                        placeholder="Select a G-Sheet column"
                                        options={gsheetIds.map(id => ({ value: id, label: id }))}
                                        value={input.gsheetId && { value: input.gsheetId, label: input.gsheetId }}
                                        onChange={(value) => handleGsheetIdOnInputChange(compIndex, inputIndex, value)}
                                      />
                                    </Grid>
                                  ))
                                }
                                </Grid>
                                {
                                compIndex < activeTemplate.specs.compositions.length - 1 && (
                                  <Divider className="mt-1" />
                                ) 
                              }
                              </div>
                            ); 
                          })
                        }
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  {
                    !get(activeTemplate, 'metas.titleGsheetId') ? (
                      <Typography variant="caption" display="block" className="colorWarning mb-2">
                        We haven't found the "video-title" column in your G-sheet, your videos will be named automatically.
                      </Typography>
                    ) : null
                  }
                  <div className="mt-2 p-2">
                    <Typography component="h6" variant="h6">
                      Video explorer
                    </Typography>
                    {
                      videosVariants.length === 0 ? (
                        <Typography variant="body2" className="colorWarning">
                          There isn't any video to create in your G-Sheet. To regenerate existing videos, you have to remove "automator-id" value in related rows.
                        </Typography>
                      ) : (
                        <div className="mt-1">
                          <Typography variant="body2" className="colorSecondary">
                            We've found {videosVariants.length} variants in your G-sheet. To regenerate existing videos, you have to remove "automator-id" value in related rows.
                          </Typography>
                          <Select
                            className="react-select darker"
                            classNamePrefix="react-select"
                            isSearchable
                            placeholder="Explore your variants"
                            options={videoExplorerSelectOptions}
                            value={get(videoExplorerSelectOptions, activeVideoIndexInExplorer) || null}
                            onChange={handleVideoExplorerSelectChange}
                          />
                          {
                            activeVideo ? (
                              <div className={clsx('mt-2 p-2', classes.defaultBackground)}>
                                {
                                  activeTemplate.specs.compositions.map((comp, compIndex) => {
                                    const { willBeIgnored, inputs, description } = comp;
                                    if (willBeIgnored) return null;
                                    return (
                                      <div key={compIndex} className="w-full mb-1">
                                        <Typography variant="subtitle1" display="inline" className="w-bold colorSecondary">
                                          { description }
                                        </Typography>
                                        <Grid container spacing={1}>
                                          {
                                          inputs.map((input, inputIndex) => {
                                            let inputWillBeIgnored = false;
                                            if (!comp.required) {
                                              inputWillBeIgnored = true;
                                              for (let i = 0; i < inputs.length; i++) {
                                                if (get(find(activeVideo.inputs, { id: inputs[i].id }), 'value') !== null) {
                                                  inputWillBeIgnored = false;
                                                  break;
                                                } 
                                              }
                                            }
                                            const value = get(find(activeVideo.inputs, { id: input.id }), 'value');
                                            return (
                                              <Grid item key={inputIndex} xs={12} sm={6} lg={4}>
                                                <Typography variant="caption">
                                                  <span className={!inputWillBeIgnored && input.required && !value ? 'colorWarning' : 'colorSecondary'}>
                                                    {input.description}:
                                                  </span> {value ? (
                                                    <React.Fragment>
                                                      {input.category === 'file' ? (
                                                        <a href={`//${value}`} target="_blank" rel="noreferrer">{value.length > 25 ? `${value.slice(0, 22)}...` : value}</a>
                                                      ) : value }
                                                    </React.Fragment>
                                                  ) : ''}
                                                </Typography>
                                              </Grid>
                                            ); 
                                          })
                                        }
                                        </Grid>
                                        {
                                        compIndex < activeTemplate.specs.compositions.length - 1 && (
                                          <Divider className="mt-1" />
                                        ) 
                                      }
                                      </div>
                                    ); 
                                  })
                                }
                              </div>
                            ) : null
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              </CardContent>
              <Button onClick={handleCreateNewJobClick}>
                <Icon className={clsx('mr-1', (remapIsNeeded || values.length < 2) ? 'colorWarning' : 'colorLight')}>cloud_upload</Icon> Create a new job
              </Button>
              {
                newJobDialogState.open && (
                  <Dialog
                    open
                    fullWidth
                    maxWidth="sm"
                    onClose={resetNewJobDialogState}
                    onConfirm={handleCreateNewJobDialogConfirm}
                    title="Are you sure?"
                    text={`By confirming, you will spend ${videosVariants.length} credits. This operation CAN'T BE CANCELED, do you want to continue?`}
                    confirmText="Create Job"
                    cancelText="Cancel"
                    disableOnConfirm={!newJobDialogState.title}
                  >
                    <TextField
                      variant="filled"
                      className="w-full"
                      value={newJobDialogState.title}
                      onChange={handleCreateNewJobTitleChange}
                      label="Job title"
                      error={!newJobDialogState.title}
                    />
                  </Dialog>
                )
              }
            </div>
          </Card>
        )
      }
    </div>
  ) : null;
}

export default GoogleSheetImporter;
