import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button, Typography, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { Dialog } from 'dnm-react-mui-root';
import useObjectState from '../utilities/hooks/useObjectState';
import apiClient from '../utilities/apiClient';
import logo from '../../assets/images/pacha-logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.nav,
  },
  buttons: {
    position: 'absolute',
    right: '20px',
  },
}));

function NavBar(props) {
  const classes = useStyles();
  const [logInDialogState, editLogInDialogState, resetEditLogInDialogState] = useObjectState({
    email: '',
    password: '',
    open: false,
  });
  const { loggedUser } = props;

  const openLogInDialog = () => editLogInDialogState({ open: true });

  useEffect(() => {
    apiClient.on('unauthorized', openLogInDialog);
    if (!firebase.auth().currentUser) openLogInDialog();
  }, []);

  const handleLogInEmailChange = (event) => {
    const { value } = event.target;
    editLogInDialogState({ email: value });
  };
  const handleLogInPasswordChange = (event) => {
    const { value } = event.target;
    editLogInDialogState({ password: value });
  };
  const handleLogInClick = () => {
    const { email, password } = logInDialogState;
    if (email && password) {
      firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
        resetEditLogInDialogState();
      }).catch((error) => {
        notify.error(error.message);
      });
    }
  };
  const handleLogOutClick = () => {
    firebase.auth().signOut().catch((error) => {
      notify.error(error.message);
    });
  };

  const handleLogInKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleLogInClick();
    }
  };

  return (
    <AppBar position="static" className={clsx('shadow', classes.root)}>
      <Toolbar>
        <img src={logo} alt="Logo" className="navbar-logo" />
        <div className={classes.buttons}>
          {loggedUser && (
            <Button className="float-right" onClick={handleLogOutClick}>
              Logout {loggedUser.email}
            </Button>
          )}
        </div>
      </Toolbar>
      {
         (logInDialogState.open || !loggedUser) && (
         <Dialog
           open
           fullWidth
           maxWidth="sm"
           onConfirm={handleLogInClick}
           title="Login"
           confirmText="Login"
           disableOnConfirm={!logInDialogState.email || !logInDialogState.password}
         >
           <TextField
             variant="filled"
             className="w-full"
             value={logInDialogState.email}
             onChange={handleLogInEmailChange}
             onKeyPress={handleLogInKeyPress}
             label="Email"
             error={!logInDialogState.email}
           />
           <TextField
             variant="filled"
             className="w-full"
             value={logInDialogState.password}
             onChange={handleLogInPasswordChange}
             onKeyPress={handleLogInKeyPress}
             label="Password"
             type="password"
             error={!logInDialogState.password}
           />
         </Dialog>
         )
      }
    </AppBar>
  );
}

export default NavBar;
