/* eslint-disable import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import '../css/index.css';
import App from './App';

document.addEventListener('DOMContentLoaded', () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyDzgasbxAvPjuDVhpRwNf-KGJxOZ0e8Mb4',
    authDomain: 'zippy-tiger-256017.firebaseapp.com',
    databaseURL: 'https://zippy-tiger-256017.firebaseio.com',
    projectId: 'zippy-tiger-256017',
    storageBucket: 'zippy-tiger-256017.appspot.com',
    messagingSenderId: '257083019921',
    appId: '1:257083019921:web:23b60bc0cfaff36c986530',
    measurementId: 'G-CPQDSXQT42',
  };
  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  window.firestore = firebase.firestore();

  window.devState = window.location.href.indexOf('localhost:5000') !== -1 ? {
    // gsheetUrl: 'https://docs.google.com/spreadsheets/d/1zpuz6dxCqTxDZhsUWJlMh1GzA64V1Q97WMPmrArA9IE/edit#gid=0',
    // tabIndex: 2,
  } : {};

  ReactDOM.render(<App />, document.getElementById('root'));
});

if (module.hot) {
  module.hot.dispose(() => {});
  module.hot.accept(() => {});
}
