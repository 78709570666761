import { clone } from 'lodash-es';
import { useState } from 'react';

function useObjectState(value = {}, doClone = false) {
  const ghost = doClone ? clone(value) : value;
  const [state, _setState] = useState(value);
  const setState = (values = {}) => {
    if (typeof values === 'function') return _setState(values);
    return _setState(_state => ({ ..._state, ...values }));
  };
  const resetState = () => setState(clone(ghost));
  return [state, setState, resetState];
}

export default useObjectState;
