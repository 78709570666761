import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, Card, CardMedia, CardContent, Button, Checkbox, TextField, InputAdornment, Icon, IconButton, Divider } from '@material-ui/core';
import { clone } from 'lodash-es';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Dialog } from 'dnm-react-mui-root';
import apiClient from '../utilities/apiClient';
import useObjectState from '../utilities/hooks/useObjectState';
import TemplateSelector from './TemplateSelector';

const useStyles = makeStyles(() => ({
  templateCardRoot: {
    display: 'flex',
    width: '100%',
    margin: 'auto',
  },
  templateCardDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  templateCardContent: {
    flex: '1 0 auto',
  },
  templateCardCover: {
    width: 151,
  },
}));

function GoogleSheetCreator(props) {
  const classes = useStyles();
  const [templateSelectionState, editTemplateSelectionState, resetTemplateSelectionState] = useObjectState({
    selectedWorkspace: null,
    selectedTemplate: null,
    selectedTemplateSpecs: null,
  });
  const [newSheetDialogState, editNewSheetDialogState, resetNewSheetDialogState] = useObjectState({
    open: false,
    title: '',
  }, true);
  const { selectedWorkspace, selectedTemplate, selectedTemplateSpecs } = templateSelectionState;
  const { open, availableTemplatesByWorkspaces } = props;
  
  useEffect(() => {
    resetTemplateSelectionState();
  }, [availableTemplatesByWorkspaces.data]);

  const inputsToCreate = useMemo(() => {
    const inputs = [];
    if (selectedTemplateSpecs) {
      selectedTemplateSpecs.compositions.forEach(comp => {
        if (!comp.disabled) {
          comp.inputs.forEach(input => {
            if (!input.disabled) inputs.push(input.id);
          });
        }
      });
    }
    return inputs;
  }, [selectedTemplateSpecs]);

  const inputsMap = useMemo(() => {
    const map = {};
    if (selectedTemplateSpecs) {
      selectedTemplateSpecs.compositions.forEach(comp => {
        if (!comp.disabled) {
          comp.inputs.forEach(input => {
            if (!input.disabled) {
              map[input._id] = input.id;
            }
          });
        }
      });
    }
    return map;
  }, [selectedTemplateSpecs]);

  const disableConfirm = useMemo(() => {
    for (const key in inputsMap) {
      if (!inputsMap[key]) return true;
    }
    return false;
  }, [inputsMap]);

  const handleSelectedWorkspaceChange = (value) => {
    editTemplateSelectionState({ selectedWorkspace: value, selectedTemplate: null, selectedTemplateSpecs: null });
  };

  const handleSelectedTemplateChange = (value) => {
    editTemplateSelectionState({ selectedTemplate: value, selectedTemplateSpecs: clone(value.specs) });
  };

  const editTemplateSpecs = (cb) => {
    const newSpecs = clone(selectedTemplateSpecs);
    cb(newSpecs);
    editTemplateSelectionState({ selectedTemplateSpecs: newSpecs });
  };

  const handleDisableCompToggle = (compIndex) => {
    editTemplateSpecs(specs => {
      const comp = specs.compositions[compIndex];
      const { disabled } = comp;
      comp.disabled = !disabled;
    });
  };

  const handleInputIdChange = (compIndex, inputIndex, value) => {
    editTemplateSpecs(specs => {
      const input = specs.compositions[compIndex].inputs[inputIndex];
      input.id = value;
    });
  };

  const handleDisableInputToggle = (compIndex, inputIndex) => {
    editTemplateSpecs(specs => {
      const input = specs.compositions[compIndex].inputs[inputIndex];
      const { disabled } = input;
      input.disabled = !disabled;
    });
  };

  const handleCreateGsheetDialogOpen = () => {
    if (disableConfirm) notify.warning('All fields must have a unique id');
    else editNewSheetDialogState({ open: true, title: selectedTemplate.label });
  };

  const handleCreateGsheetDialogClose = () => {
    resetNewSheetDialogState();
  };

  const handleGsheetTitleChange = (event) => {
    const { value } = event.target;
    editNewSheetDialogState({ title: value });
  };

  const handleCreateGsheetDialogConfirm = () => {
    loading.show('A new G-sheet is being created...');
    apiClient.fetch('gsheet/create', { 
      title: newSheetDialogState.title, 
      templateId: selectedTemplate.value,
      fields: inputsToCreate,
      inputsMap,
    }).then(res => {
      const { success, message, url } = res;
      if (!success && message !== 'bad_token') notify.error(message);
      else if (success) {
        window.open(url);
      }
    }).catch(e => {
      notify.error(e.message || e.toString());
    }).finally(() => {
      resetNewSheetDialogState();
      loading.hide();
    });
  };

  return open ? (
    <div>
      <TemplateSelector 
        data={availableTemplatesByWorkspaces.data}
        isLoading={availableTemplatesByWorkspaces.isLoading} 
        workspace={selectedWorkspace}
        onWorkspaceChange={handleSelectedWorkspaceChange} 
        template={selectedTemplate}
        onTemplateChange={handleSelectedTemplateChange}
      />
      {
        selectedTemplate && (
          <Card className={clsx(classes.templateCardRoot, 'mt-2')}>
            <CardMedia
              image={selectedTemplate.thumbnail}
              title={selectedTemplate.label}
              className={classes.templateCardCover}
            />
            <div className={classes.templateCardDetails}>
              <CardContent className={classes.templateCardContent}>
                <Typography component="h5" variant="h5">
                  {selectedTemplate.label}
                </Typography>
                {
                  selectedTemplateSpecs.compositions.map((comp, compIndex) => (
                    <div key={compIndex} className="w-full mt-2">
                      {
                        !comp.required && (
                          <Checkbox
                            checked={!comp.disabled}
                            onChange={() => handleDisableCompToggle(compIndex)}
                          />
                        )
                      }
                      <Typography variant="subtitle1" display="inline" className={clsx(comp.required && 'ml-1', 'colorLight', comp.disabled && 'lowOpacity')}>
                        { comp.description }
                      </Typography>
                      {
                        !comp.disabled && (
                          <Grid container spacing={1}>
                            {
                              comp.inputs.map((input, inputIndex) => (
                                <Grid item key={inputIndex} xs={12} sm={6} lg={4}>
                                  <TextField
                                    variant="filled"
                                    className={clsx('w-full', input.disabled && 'disabledInput', !input.disabled && !input.id && 'colorWarning')}
                                    value={input.id}
                                    onChange={(event) => handleInputIdChange(compIndex, inputIndex, event.target.value)}
                                    label={input.description}
                                    onClick={input.disabled ? () => handleDisableInputToggle(compIndex, inputIndex) : null}
                                    InputProps={{
                                      endAdornment: !input.required && (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={input.disabled ? null : () => handleDisableInputToggle(compIndex, inputIndex)}
                                          >
                                            <Icon className="colorSecondary">{ input.disabled ? 'add_circle_outline' : 'remove_circle_outline' }</Icon>
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                              ))
                            }
                          </Grid>
                        )
                      }
                      {
                        compIndex < selectedTemplateSpecs.compositions.length - 1 && (
                          <Divider className="mt-1" />
                        ) 
                      }
                    </div>
                  ))
                }
                <div className="mt-2 pt-2">
                  <Typography variant="caption" color="secondary">
                    These fields will be created: { inputsToCreate.join(', ') }
                  </Typography>
                </div>
              </CardContent>
              <Button onClick={handleCreateGsheetDialogOpen}>
                <Icon className={clsx('mr-1', disableConfirm ? 'colorWarning' : 'colorLight')}>description</Icon> Create new G-Sheet
              </Button>
              {
                newSheetDialogState.open && (
                  <Dialog
                    open
                    fullWidth
                    maxWidth="sm"
                    onClose={handleCreateGsheetDialogClose}
                    onConfirm={handleCreateGsheetDialogConfirm}
                    title="Give a title to your G-Sheet"
                    confirmText="Create"
                    cancelText="Cancel"
                    disableOnConfirm={!newSheetDialogState.title}
                  >
                    <TextField
                      variant="filled"
                      className="w-full"
                      value={newSheetDialogState.title}
                      onChange={handleGsheetTitleChange}
                      label="Title"
                      error={!newSheetDialogState.title}
                    />
                  </Dialog>
                )
              }
            </div>
          </Card>
        )
      }
    </div>
  ) : null;
}

export default GoogleSheetCreator;
