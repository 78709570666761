import React, { useEffect, useState, useMemo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Tabs, Tab, Icon, Typography } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Dialog, MuiRoot } from 'dnm-react-mui-root';
import { clone } from 'lodash-es';
import muiTheme from './styles/theme';
import NavBar from './components/NavBar';
import GoogleSheetCreator from './components/GoogleSheetCreator';
import GoogleSheetImporter from './components/GoogleSheetImporter';
import GoogleSheetJobManager from './components/GoogleSheetJobManager';
import apiClient from './utilities/apiClient';
import useObjectState from './utilities/hooks/useObjectState';

const useStyles = makeStyles((theme) => ({
  darkGradient: {
    background: theme.palette.gradient.dark,
  },
  successGradient: {
    background: theme.palette.gradient.success,
  },
  warningGradient: {
    background: theme.palette.gradient.warning,
  },
  errorGradient: {
    background: theme.palette.gradient.error,
  },
  infoGradient: {
    background: theme.palette.gradient.info,
  },
  notifyContent: {
    padding: '10px 20px 10px 20px',
    fontSize: '16px',
    fontWeight: 500,
  },
  darkBackground: {
    backgroundColor: theme.palette.background.dark,
  },
  lightTypo: {
    color: theme.palette.primary.light,
  },
}));

function App() {
  const [waitingForGoogleTokenState, setWaitingForGoogleTokenStateChange, resetWaitingForGoogleTokenStateChange] = useObjectState({
    authUrl: null,
    isLoading: false,
  }, true);
  const [tabIndex, setTabIndex] = useState(window.devState.tabIndex || 0);
  const [loggedUser, _setLoggedUser] = useState(null); 
  const [availableTemplatesByWorkspaces, editAvailableTemplatesByWorkspaces, resetAvailableTemplatesByWorkspaces] = useObjectState({
    isLoading: false,
    data: [],
  });
  const [appIsInitializing, setAppIsInitializing] = useState(true); 
  const [jobs, setJobs] = useState([]);
  const classes = useStyles();

  const setLoggedUser = user => {
    const resetUser = () => {
      apiClient.setAuthToken(null);
      _setLoggedUser(null);
      setAppIsInitializing(false);
    };
    if (!user) resetUser();
    else {
      firebase.auth().currentUser.getIdToken(true).then(token => {
        apiClient.setAuthToken(token);
        _setLoggedUser(user);
        setAppIsInitializing(false);
      }).catch(e => {
        resetUser();
        notify.error(e);
      });
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user && window.openLogInDialog) window.openLogInDialog();
      setLoggedUser(user);
    });
  }, []);

  // Get a list of jobs
  useEffect(() => {
    if (loggedUser) {
      apiClient.fetch('job/list').then(res => {
        const { success, message, jobsList } = res;
        if (!success) notify.error(message);
        else {
          const _jobs = jobsList.map(jobId => ({ id: jobId }));
          setJobs(_jobs);
        }
      }).catch(e => {
        notify.error(e.message || e.toString());
      });
    } else setJobs([]);
  }, [loggedUser]);

  // Get a list of template for the loggedUser
  useEffect(() => {
    if (loggedUser) {
      editAvailableTemplatesByWorkspaces({ isLoading: true });
      apiClient.fetch('templates/get').then(res => {
        const { success, templates, message } = res;
        if (success) editAvailableTemplatesByWorkspaces({ data: templates });
        else notify.error(message);
      }).catch(e => notify.error(e)).finally(() => editAvailableTemplatesByWorkspaces({ isLoading: false }));
    } else resetAvailableTemplatesByWorkspaces([]);
  }, [loggedUser]);

  // Listen user modifications in firestore => for G-Sheet reauth for example
  useEffect(() => {
    let unsubscribe = null;
    if (loggedUser) {
      unsubscribe = firestore.collection('users').doc(firebase.auth().currentUser.uid)
        .onSnapshot((doc) => {
          const { authUrl } = doc.data();
          setWaitingForGoogleTokenStateChange(state => {
            if (authUrl !== null && authUrl !== state.authUrl) {
              return { authUrl, isLoading: false };
            } 
            if (state.isLoading) notify.success({ message: 'Your account is now connected to G-Sheet', timeout: 0 });
            return { authUrl: null, isLoading: false };
          });
        });
    } else resetWaitingForGoogleTokenStateChange();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [loggedUser]);

  const notifySettings = useMemo(() => ({
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'top',
    },
    direction: 'down',
    style: 'full',
    customClasses: {
      content: classes.notifyContent,
      warning: `${classes.warningGradient}`,
      info: `${classes.infoGradient}`,
      error: `${classes.errorGradient}`,
      success: `${classes.successGradient}`,
    },
  }), []);
  const dialogSettings = useMemo(() => ({
    classes: {
      fullscreenAppbar: `${classes.darkBackground} ${classes.lightTypo}`,
    },
  }), []);
  
  const handleTabIndexChange = (event, value) => setTabIndex(value);

  const handleConnectToGSheetDialogConfirm = () => {
    if (waitingForGoogleTokenState.authUrl) {
      setWaitingForGoogleTokenStateChange({ isLoading: true });
      window.open(waitingForGoogleTokenState.authUrl);
    }
  };

  const handleJobCreation = (jobId, jobData = null) => {
    setJobs(_jobs => [..._jobs, { id: jobId, data: jobData }]);
    handleTabIndexChange(null, 2);
  };

  const handleJobItemRemove = (jobId) => {
    setJobs(_jobs => {
      const newJobs = clone(_jobs);
      let index = -1;
      for (let i = 0; i < newJobs.length; i++) {
        const job = newJobs[i];
        if (job.id === jobId) {
          index = i;
          break;
        }
      }
      if (index !== -1) newJobs.splice(index, 1);
      return newJobs;
    });
  };

  const handleJobItemChange = (jobId, jobData = null) => {
    setJobs(_jobs => {
      const newJobs = clone(_jobs);
      for (let i = 0; i < newJobs.length; i++) {
        const job = newJobs[i];
        if (job.id === jobId) {
          job.data = jobData;
          break;
        }
      }
      return newJobs;
    });
  };

  return (
    <MuiRoot
      notifySettings={notifySettings}
      dialogSettings={dialogSettings}
      isLoading={appIsInitializing}
    >
      {
        !appIsInitializing ? (
          <React.Fragment>
            <NavBar loggedUser={loggedUser} />
            {
              loggedUser ? (
                <React.Fragment>
                  { waitingForGoogleTokenState.authUrl && (
                    <Dialog
                      open
                      fullWidth
                      maxWidth="sm"
                      onClose={resetWaitingForGoogleTokenStateChange}
                      onConfirm={handleConnectToGSheetDialogConfirm}
                      title="Authentification to G-Sheet required"
                      confirmText="Continue"
                      cancelText="Close"
                    >
                      <Typography variant="caption">
                        { waitingForGoogleTokenState.isLoading ? 'Connecting to G-Sheet...' : 'You have to autorize us to access to your G-Sheet account to continue using this service.' }
                      </Typography>
                    </Dialog>
                  ) }
                  <div className="page">
                    <Tabs
                      value={tabIndex}
                      onChange={handleTabIndexChange}
                      indicatorColor="secondary"
                      textColor="secondary"
                    >
                      <Tab icon={<Icon>create_new_folder</Icon>} label="Create G-Sheet" />
                      <Tab icon={<Icon>cloud_download</Icon>} label="Import from G-Sheet" />
                      <Tab icon={<Icon>video_library</Icon>} label="Jobs" />
                    </Tabs>
                    <div className="p-2">
                      <GoogleSheetCreator availableTemplatesByWorkspaces={availableTemplatesByWorkspaces} open={tabIndex === 0} />
                      <GoogleSheetImporter onJobCreation={handleJobCreation} availableTemplatesByWorkspaces={availableTemplatesByWorkspaces} open={tabIndex === 1} />
                      <GoogleSheetJobManager jobs={jobs} onJobItemChange={handleJobItemChange} onJobItemRemove={handleJobItemRemove} open={tabIndex === 2} />
                    </div>
                  </div>
                </React.Fragment>
              ) : null
            }
          </React.Fragment>
        ) : null
      }
    </MuiRoot>
  );
}

function AppWithTheme() {
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={muiTheme}>
        <App />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default AppWithTheme;
